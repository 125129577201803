import "./App.css";
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Home from './pages/Home'
import Projects from './pages/Projects'
import Experience from './pages/Experience'
import Navbar  from "./components/Navbar";
import Footer from "./components/Footer";
import ProjectDisplay from "./pages/ProjectDisplay";

function App(){
    return <div className="App">
        <Router>
            <Navbar />
            <Routes>
                <Route exact path="/" element = {<Home />} />
                <Route path="/projects" element = {<Projects />} />
                <Route path="/project/:id" element={<ProjectDisplay />} />
                <Route path="/experience" element = {<Experience />} />
                <Route path="*" element={<Navigate to="/" />} />
            </Routes>
            <Footer />
        </Router>
    </div>
}

export default App;